<template>
  <div class="settlement-page">
    <div class="flex">
      <el-select
          size="medium"
          v-model="outlet_id"
          collapse-tags
          placeholder="Харилцагч сонгох"
          style="margin: 0px 5px 10px 15px; width: 20%"
        >
          <el-option
            v-for="(item, index) in outlets"
            :key="index"
            :label="item.full_name_eng"
            :value="item._id"
          >
          </el-option>
        </el-select>
        <el-select
          style="margin-right: 5px; width: 10%;"
          v-model="year"
        >
          <el-option
            v-for="item in yearChoices"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <el-select
          style="margin-right: 5px; width: 10%"
          v-model="season"
        >
          <el-option value="1" label="1-р хагас"></el-option>
          <el-option value="2" label="2-р хагас"></el-option>
        </el-select>
      <el-button
        class="ml-2"
        type="success"
        size="small"
        @click="getOutletSettlementForAct()">Хайх
      </el-button>
    </div>
    <div class="settlementBody" id="settlementBody">
      <div>
        <img :src="logo" style="width: 60px; height: 35px; margin-top: 0;">
        <div style="margin: 1% 0% 7%;">
          <div style="float: left; margin: 0px; padding: 0px;">
            <p style="font-size: 8px;"><b>НХМаягт Т-3</b></p>
          </div>
          <div style="float: right; margin: 0px; padding: 0px; width: 180px;">
              <p style="font-size: 8px;"><b>Сангийн сайдын 2017 оны 347 дугаар тушаалын хавсралт</b></p>
          </div>
        </div>
        <div>
          <p class="title"><strong>ТООЦООНЫ ҮЛДЭГДЛИЙН БАТАЛГАА №</strong></p>
          <table style="width: 100%;">
              <tr>
                  <td style="width: 20%;"><strong style="font-size: 12px;">Хариуцагч: </strong></td>
                  <td style="font-size: 12px; width: 30%;">Ток Ток ХХК</td>
                  <td><strong style="font-size: 12px; width: 20%;">Нэхэмжлэгч:</strong></td>
                  <td style="font-size: 12px; width: 30%;">{{actData.outlet_name}}</td>
              </tr>
              <tr>
                  <th rowspan="2"><strong style="font-size: 12px;">Байгууллагын 	нэр: </strong></th>
                  <th rowspan="2" style="font-size: 12px;">Ток Ток ХХК</th>
                  <td><strong style="font-size: 12px;">Байгууллагын нэр: </strong></td>
                <td style="font-size: 12px;">{{actData.company_name}}</td>
              </tr>
              <tr>
                  <td><strong style="font-size: 12px;">Байгууллагын рд:</strong></td>
                  <td style="font-size: 12px;">{{actData.vat_regno}}</td>
              </tr>
              <tr>
                  <td><strong style="font-size: 12px; height: 65px; width: 300px">Хаяг:</strong></td>
                  <td style="font-size: 12px; height: 65px; width: 300px">Улаанбаатар хот, Баянгол дүүрэг 3-р хороо, Агро төв 5 давхар 503 тоот</td>
                  <td><strong style="font-size: 12px; height: 65px; width: 300px">Хаяг:</strong></td>
                  <td style="font-size: 12px; height: 65px; width: 300px">{{actData.address}}</td>
              </tr>
              <tr>
                  <td><strong style="font-size: 12px;">Утас, факс: </strong></td>
                  <td style="font-size: 12px;">95205005</td>
                  <td><strong style="font-size: 12px;">Утас, факс: </strong></td>
                  <td style="font-size: 12px;">{{actData.phone}}</td>
              </tr>
              <tr>
                  <td><strong style="font-size: 12px;">Э-шуудан: </strong></td>
                  <td style="font-size: 12px;">accountant4@toktok.mn</td>
                  <td><strong style="font-size: 12px;">Э-шуудан: </strong></td>
                  <td style="font-size: 12px;">{{actData.email}}</td>
              </tr>
              <tr>
                  <td><strong style="font-size: 12px;">Банкны нэр:</strong></td>
                  <td style="font-size: 12px;">Хаан Банк</td>
                  <td><strong style="font-size: 12px;">Банкны нэр:</strong></td>
                  <td style="font-size: 12px;">{{actData.bank_account_name}}</td>
              </tr>
              <tr>
                  <td><strong style="font-size: 12px;">Дансны дугаар:</strong></td>
                  <td style="font-size: 12px;">5173080709</td>
                  <td><strong style="font-size: 12px;">Дансны дугаар:</strong></td>
                  <td style="font-size: 12px;">{{actData.bank_account_number}}</td>
              </tr>
          </table>
          <div style="margin-top: 3%;">
            <p class="title"><b>ТООЦОО НИЙЛСЭН ДАНСНЫ №</b></p>
            <p style="text-align: justify; font-size: 12px;">Нэхэмжлэгчийн нэрийн өмнөөс ........................ ажилтай
            ...................... овогтой .................... нь төлөгчийн нэрийн өмнөөс тооцооны
            ажилтан Н овогтой Энхтуул -тай тус хоёр байгууллагын хооронд {{this.date1}} өдрөөс
            {{this.date2}} өдрийг дуустал хугацаанд өгч авалцсан зүйлээ хоёр байгууллагад хөтлөгдөж
            буй нягтлан бодох бүртгэлийн дэлгэрэнгүй ба хураангуй бүртгэлийн бичилтээр нэг бүрчлэн нийлж
            үзэхэд Ток ток ХХК нь {{formatPrice(actData.withdraw_amount_calculated) || 0}} төгрөгний өглөгтэй гарсныг харилцан батлав.</p>
            <p style="font-size: 12px;"><b>Тооцоо нийлсэн байгууллагууд:</b></p>
            <div>
              <div style="float: left; margin: 0px; padding: 0px;">
                <p style="font-size: 10px;"><b>Ток Ток ХХК</b></p>
                <img :src="signature" class="signature2" style="width: 80%; height: 20%;">
                <p class="signature1" style="font-size: 10px;">Тооцооны ажилтан: ..................................... /Н.Энхтуул/</p>
                <p style="font-size: 10px; margin: 0; padding-bottom: 0px;">(Тамга)</p>
                <img :src="tamga" style="width: 150px; height: 140px; margin-top: 0;">
              </div>
              <div style="float: right; margin: 0px; padding: 0px;">
                  <p style="font-size: 10px;"><b>{{actData.company_name}}</b></p>
                  <p style="font-size: 10px; margin-top: 25px;">Нягтлан бодогч:  ....................................... /__________________/</p>
                <p style="font-size: 10px;">(Тамга)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-row style="margin-top: 15px; margin-left:35%">
      <el-col :span=6>
        <!-- <el-button type="success" :disabled="!isDownloadable" @click="download()">Татаж авах</el-button> -->
        <el-button type="success" @click="download()">Татаж авах</el-button>
      </el-col>
      <el-col :span=4>
        <el-button type="danger" @click="sentFile()">Файл оруулах</el-button>
      </el-col>
    </el-row>
    <el-dialog
      title="Файл илгээх"
      :visible.sync="dialogVisible"
      width="30%"
      style="height:100%;">
      <div style="width: 450px; height: 400px; text-align: center;">
        <h4 style="margin-left: 50px"><i style="color: red">Санамж :</i>Таны оруулсан файл тамга болон гарын үсгээр баталгаажсан байх ёстойг анхаарна уу !!!</h4>
        <el-upload
          list-type="picture"
          action="#"
          :on-remove="handleRemove"
          accept=".pdf, .jpg, .jpeg, .png, .JPG, .JPEG"
          :limit="1"
          :on-change="(file) => sendActToEmail(actData.outlet_id, file, actData.outlet_name)"
          :auto-upload="false">
          <el-row v-if="file">
            <i class="el-icon-check mr5" />File Амжилттай хуулагдлаа.
          </el-row>
          <el-card v-else style="width:100%;">
            <i class="el-icon-upload"></i>
            <h4>Файл оруулах</h4>
          </el-card>
          <div class="el-upload__text" style="width: 200px; height: 20px"></div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">Cancel</el-button>
        <el-button type="danger" @click="sentFile(actData.outlet_id, file, actData.outlet_name)">Файл илгээх</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import toktokLogo from '@/assets/toktoklogo.png'
import toktokTamga from '@/assets/tamga.png'
import signature from '@/assets/signature.png'
import helper from '../../../helper/helper'
import * as dayjs from 'dayjs'
import { getBase64 } from '../../../helper/custom'
import { getToken } from '../../../helper/auth'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  data () {
    return {
      logo: toktokLogo,
      tamga: toktokTamga,
      signature: signature,
      allOutletList: [],
      outlet_id: null,
      actData: {},
      season: 1,
      year: dayjs().year(),
      yearChoices: [],
      date1: '',
      date2: '',
      file: '',
      dialogVisible: false,
      isDownloadable: false,
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  created () {
    this.outlets = this.$root.chosenOutlets
    if (this.outlets.length > 0) this.outlet_id = this.$root.chosenOutlets[0]._id || this.outlets[0]._id
    this.getOutletSettlementForAct(this.outlet_id, this.dateRange, this.year)
    this.calculateDate()
    this.calculateYearChoices()
  },
  methods: {
    async calculateYearChoices() {
      const currentYear = dayjs().year()
      this.yearChoices = [currentYear]
      for (let i = 1; i <= 2; i++) {
        this.yearChoices.push(currentYear - i)
      }
    },
    async calculateDate () {
      const dateNow = dayjs().format('YYYY-MM-DD')
      if (((dayjs().format('YYYY-01-01') < dateNow) && (dayjs().format('YYYY-04-01') > dateNow)) || ((dayjs().format('YYYY-07-01') < dateNow) && (dayjs().format('YYYY-07-15') > dateNow))) {
        this.isDownloadable = true
      }
    },
    async getOutletSettlementForAct () {
      const requestOptions = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await getToken()}`
        }
      }
      if (parseInt(this.season) === 2) {
        this.date1 = `${this.year} оны 07 сарын 01`
        this.date2 = `${this.year} оны 12 сарын 31`
      } else {
        this.date1 = `${this.year} оны 01 сарын 01`
        this.date2 = `${this.year} оны 06 сарын 31`
      }
      await axios.get(`https://rest.toktok.mn/client-api-test/no-auth/settlement/act?outlet_id=${this.outlet_id}&year=${this.year}&season=${this.season}`, requestOptions)
        .then(axiosRes => {
          if (axiosRes.data.status === 'success') {
            this.actData = axiosRes.data.data
          } else {
            self.$notify({
              title: 'Амжилтгүй',
              message: axiosRes.error,
              type: 'warning'
            })
          }
        })
        .catch(error => {
          self.$notify.error({
            title: 'Уучлаарай',
            position: 'bottom-left',
            message: error.message,
            duration: 2000
          })
        })
    },
    formatPrice (price) {
      return helper.formatPrice(price)
    },
    getBase64ImageFromURL (url) {
      return new Promise((resolve, reject) => {
        var img = new Image()
        img.setAttribute('crossOrigin', 'anonymous')

        img.onload = () => {
          var canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height

          var ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)

          var dataURL = canvas.toDataURL('image/png')

          resolve(dataURL)
        }
        img.onerror = error => {
          reject(error)
        }
        img.src = url
      })
    },
    async download () {
      const docDefinition = {
        content: [
          {
            image: await this.getBase64ImageFromURL(this.logo),
            width: 60,
            height: 35,
            margin: [0, 0, 0, 5]
          },
          {
            columns: [
              { text: 'НХМаягт Т-3', alignment: 'left', style: 'invoice_description' },
              { text: '', alignment: 'left', style: 'invoice_description' },
              { text: 'Сангийн сайдын 2017 оны 12 дугаар сарын 5-ны өдрийн 347 тоот тушаалын хавсралт', alignment: 'right', style: 'invoice_description' }
            ],
            columnGap: 10
          },
          { text: 'ТООЦООНЫ ҮЛДЭГДЛИЙН БАТАЛГАА №', alignment: 'center', style: 'invoice_header', margin: [0, 20, 0, 20] },
          {
            layout: {
              hLineWidth: function (i, node) {
                return 0.5
              },
              vLineWidth: function (i, node) {
                return 0.5
              }
            },
            table: {
              headerRows: 1,
              widths: [90, 155, 90, 155],
              body: [
                [{ text: 'Хариуцагч:', style: ['text_table_data', 'text_bold'] }, { text: 'ТокТок ХХК', style: 'text_table_data' }, { text: 'Нэхэмжлэгч:', style: ['text_table_data', 'text_bold'] }, { text: this.actData.outlet_name, style: 'text_table_data' }],
                [{ text: 'Байгууллагын нэр:', border: [true, true, true, false], style: ['text_table_data', 'text_bold'] }, { text: 'ТокТок ХХК', border: [true, true, true, false], style: 'text_table_data' }, { text: 'Байгууллагын нэр:', style: ['text_table_data', 'text_bold'] }, { text: this.actData.company_name, style: 'text_table_data' }],
                [{ text: '', border: [true, false, false, true] }, { text: '', border: [true, false, false, true] }, { text: 'Байгууллагын рд:', style: ['text_table_data', 'text_bold'] }, { text: this.actData.outlet_name, style: 'text_table_data' }],
                [{ text: 'Хаяг:', style: ['text_table_data', 'text_bold'] }, { text: 'Улаанбаатар хот, Баянгол дүүрэг 3-р хороо, Агро төв 5 давхар 503 тоот', style: 'text_table_data' }, { text: 'Хаяг:', style: ['text_table_data', 'text_bold'] }, { text: this.actData.address, style: 'text_table_data' }],
                [{ text: 'Утас, факс:', style: ['text_table_data', 'text_bold'] }, { text: '95205005', style: 'text_table_data' }, { text: 'Утас, факс:', style: ['text_table_data', 'text_bold'] }, { text: this.actData.phone, style: 'text_table_data' }],
                [{ text: 'Э-шуудан:', style: ['text_table_data', 'text_bold'] }, { text: 'stockkeeper@toktok.mn', style: 'text_table_data' }, { text: 'Э-шуудан:', style: ['text_table_data', 'text_bold'] }, { text: this.actData.email, style: 'text_table_data' }],
                [{ text: 'Банкны нэр:', style: ['text_table_data', 'text_bold'] }, { text: 'Хаан Банк', style: 'text_table_data' }, { text: 'Банкны нэр:', style: ['text_table_data', 'text_bold'] }, { text: this.actData.bank_account_name, style: 'text_table_data' }],
                [{ text: 'Дансны дугаар:', style: ['text_table_data', 'text_bold'] }, { text: '5173080709', style: 'text_table_data' }, { text: 'Дансны дугаар:', style: ['text_table_data', 'text_bold'] }, { text: this.actData.bank_account_number, style: 'text_table_data' }]
              ]
            }
          },
          {
            columns: [
              { text: 'ТООЦОО НИЙЛСЭН ДАНСНЫ №:', alignment: 'left', style: 'invoice_header', margin: [120, 20, 0, 10] }
            ]
          },
          {
            columns: [
              { text: `Нэхэмжлэгчийн нэрийн өмнөөс ........................ ажилтай ...................... овогтой .................... нь төлөгчийн нэрийн өмнөөс тооцооны ажилтан Н овогтой Энхтуул -тай тус хоёр байгууллагын хооронд ${this.date1} өдрөөс ${this.date2} өдрийг дуустал хугацаанд өгч авалцсан зүйлээ хоёр байгууллагад хөтлөгдөж буй нягтлан бодох бүртгэлийн дэлгэрэнгүй ба хураангуй бүртгэлийн бичилтээр нэг бүрчлэн нийлж үзэхэд Ток ток ХХК нь ${this.formatPrice(this.actData.withdraw_amount_calculated)} төгрөгний өглөгтэй гарсныг харилцан батлав.`, alignment: 'justify', style: 'font-size: 10px', margin: [0, 5, 0, 5] }
            ]
          },
          {
            columns: [
              { text: 'Тооцоо нийлсэн байгууллагууд:', alignment: 'left', style: 'invoice_header', margin: [0, 20, 0, 10] }
            ]
          },
          {
            columns: [
              { text: 'Ток Ток ХХК', alignment: 'left', style: 'text_bold', margin: [0, 5, 0, 0] },
              { text: this.actData.company_name, alignment: 'left', style: 'text_bold', margin: [0, 5, 0, 0] }
            ]
          },
          {
            columns: [
              { text: 'Тооцооны ажилтан: ............................ /Н.Энхтуул/', alignment: 'left', style: 'text_body', margin: [0, 20, 0, 0] },
              { text: 'Нягтлан бодогч: ................................. /__________________/', alignment: 'left', style: 'text_body', margin: [0, 20, 0, 0] }
            ]
          },
          {
            image: await this.getBase64ImageFromURL(this.signature),
            width: 150,
            height: 30,
            margin: [100, -20, 0, 0]
          },
          {
            columns: [
              { text: '(Тамга)', alignment: 'left', style: 'text_body', margin: [0, 10, 0, 0] },
              { text: '(Тамга)', alignment: 'left', style: 'text_body', margin: [0, 10, 0, 0] }
            ]
          },
          {
            image: await this.getBase64ImageFromURL(this.tamga),
            width: 150,
            height: 140
          },
          {
            columns: [
              { text: dayjs().format('YYYY-MM-DD'), alignment: 'center', style: 'text_body', margin: [0, 40, 0, 0] }
            ]
          }
        ],
        styles: {
          invoice_description: {
            fontSize: 8
          },
          invoice_header: {
            fontSize: 12,
            bold: true
          },
          text_bold: {
            fontSize: 10,
            bold: true
          },
          text_body: {
            fontSize: 10
          },
          text_body_bold: {
            fontSize: 11,
            bold: true
          },
          text_table_data: {
            fontSize: 11,
            alignment: 'left'
          }
        }
      }
      pdfMake.createPdf(docDefinition).print()
    },
    async sendActToEmail (outletId, file, outletName) {
      const type = file.raw.type.split('/')
      await getBase64(file.raw).then(response => {
        this.file = response
      })
      const payload = {
        outlet_id: outletId,
        file: this.file,
        outlet_name: outletName,
        type: type[1],
        contentType: file.raw.type
      }
      await axios.post('https://rest.toktok.mn/client-api-test/no-auth/settlement/settlementActUpload', payload)
        .then(axiosRes => {
          if (axiosRes.data.status === 'success') {
            this.$notify({
              title: 'Амжилттай илгээгдлээ !!!',
              message: axiosRes.message,
              type: 'success'
            })
            this.close()
          } else {
            this.$notify({
              title: 'Амжилтгүй',
              message: axiosRes.error,
              type: 'warning'
            })
          }
        })
        .catch(error => {
          this.$notify.error({
            title: 'Уучлаарай',
            position: 'bottom-left',
            message: error.message,
            duration: 2000
          })
        })
    },
    sentFile () {
      this.dialogVisible = true
    },
    close () {
      this.dialogVisible = false
    },
    handleRemove () {
      this.file = ''
    }
  }
}
</script>
<style scoped>
table,td, th {
  text-align: left;
  border: 0.1px solid black;
  border-collapse: collapse;
}
.title {
  font-size: 10px;
  margin-left: 30%;
}
#settlementBody {
  font-family: 'Times New Roman', Times, serif;
  position: relative;
  background-color: white;
  display: flex;
  justify-content: center;
  height: 20.5cm;
  width: 14.8cm;
  margin: auto;
  padding: 0.5cm 0.5cm 0cm 0.5cm;
}
.signature1 {
  position: sticky;
  margin-bottom: 10px;
  margin-top: 25px;
}
.signature2 {
  position: absolute;
  padding-left: 40%;
  padding-top: 5%;
}
</style>
